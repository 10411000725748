import { useCallback, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { isUndefined, last, merge } from "lodash";
import useSWR, { SWRConfiguration, SWRResponse, useSWRConfig } from "swr";
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from "swr/infinite";
import { z } from "zod";

import { useAuth } from "@contexts/auth";
import fetchJSON from "@lib/fetch-json";

import * as accounts from "./definitions/accounts";
import * as appointments from "./definitions/appointments";
import * as authorization from "./definitions/authorization";
import * as availabilityBlocks from "./definitions/availability-blocks";
import * as clients from "./definitions/clients";
import * as coupons from "./definitions/coupons";
import * as dropboxsign from "./definitions/dropboxsign";
import * as generatedEmail from "./definitions/generated-email";
import * as importClients from "./definitions/import-clients";
import * as labels from "./definitions/labels";
import * as library from "./definitions/library";
import * as note from "./definitions/note";
import * as packageInstances from "./definitions/package-instances";
import * as packages from "./definitions/packages";
import * as products from "./definitions/products";
import * as schedulers from "./definitions/schedulers";
import * as smartActions from "./definitions/smart-actions";
import * as stripe from "./definitions/stripe";
import * as todos from "./definitions/todos";
import * as webflow from "./definitions/webflow";
import { SchemaDefinition } from "./common";

type UseApiResponse<K extends SchemaDefinition> = {
  loading: boolean;
  apiCall: (
    path: z.input<K["input"]>["path"],
    body: z.input<K["input"]>["body"],
    query: z.input<K["input"]>["query"],
    requestId?: string
  ) => Promise<AxiosResponse<z.infer<K["output"]>> | undefined>;
  error: Error | undefined;
};

export type UseApiConfig = {
  failMode: "state" | "throw";
};

export const ENDPOINTS = {
  ...library,
  ...todos,
  ...note,
  ...labels,
  ...importClients,
  ...smartActions,
  ...coupons,
  ...dropboxsign,
  ...webflow,
  ...stripe,
  ...authorization,
  ...appointments,
  ...packageInstances,
  ...packages,
  ...clients,
  ...products,
  ...accounts,
  ...schedulers,
  ...availabilityBlocks,
  ...generatedEmail,
};

const fillPath = (pathString: string, path: any) => {
  if (!path || !Object.keys(path).length) return pathString;

  const re = new RegExp(
    Object.keys(path)
      .map((x) => `{${x}}`)
      .join("|"),
    "gi"
  );

  // TODO Throw error if some not matched
  return pathString.replace(
    re,
    (matched: string) => path[matched.replace(/{|}/g, "")]
  );
};

export const api = <K extends SchemaDefinition>(
  endpoint: K,
  inputs: z.input<K["input"]>,
  requestId?: string
): Promise<AxiosResponse<z.infer<K["output"]>>> => {
  const { body, path, query } = inputs;

  return axios.request({
    method: endpoint.axios.type,
    url: fillPath(endpoint.axios.path, path),
    params: query,
    data: body,
    ...(requestId ? { headers: { "X-Request-Id": requestId } } : {}),
  });
};

export const useUserApi = <K extends SchemaDefinition>(
  endpoint: K,
  config?: UseApiConfig
): UseApiResponse<K> => {
  const { uid } = useAuth();

  return useApi(
    {
      ...endpoint,
      axios: {
        ...endpoint.axios,
        path: `/api/v1/users/${uid}${endpoint.axios.path}`,
      },
    },
    config
  );
};

export const useApi = <K extends SchemaDefinition>(
  endpoint: K,
  config?: UseApiConfig
): UseApiResponse<K> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  type Input = z.infer<K["input"]>;
  const failMode = config?.failMode || "state";

  const apiCall = useCallback(
    async (
      path: Input["path"],
      body: Input["body"],
      query: Input["query"],
      requestId?: string
    ) => {
      setLoading(true);
      try {
        return await api(endpoint, { path, body, query }, requestId);
      } catch (e) {
        setError(e as Error);
        if (failMode === "throw") {
          throw e;
        }
      } finally {
        setLoading(false);
      }

      return undefined;
    },
    [endpoint, failMode]
  );

  return { loading, apiCall, error };
};

type ApiGetSignature = <K extends SchemaDefinition>(
  endpoint?: K,
  path?: z.input<K["input"]>["path"],
  query?: z.input<K["input"]>["query"],
  config?: SWRConfiguration
) => SWRResponse<z.infer<K["output"]>> & { loading: boolean };

type ApiGetInfiniteSignature = <K extends SchemaDefinition>(
  endpoint?: K,
  path?: z.input<K["input"]>["path"],
  query?: z.input<K["input"]>["query"],
  config?: SWRInfiniteConfiguration
) => SWRInfiniteResponse<z.infer<K["output"]>> & { loading: boolean };

type ApiGetMutateSignature = <K extends SchemaDefinition>(
  endpoint?: K,
  path?: z.input<K["input"]>["path"],
  query?: z.input<K["input"]>["query"],
  config?: {
    ignoreQuery?: boolean;
  }
) => () => void;

const hasMissingValues = (...args: any[]) => {
  // @ts-ignore this is fine, we're spreading an unknown number of args into the merge function
  return Object.values(merge(...args)).some(isUndefined);
};

export const useApiGet: ApiGetSignature = (endpoint, path, query, config) => {
  const filledPath = endpoint ? fillPath(endpoint.axios.path, path) : null;

  const url =
    filledPath && query
      ? `${filledPath}?${new URLSearchParams(query)}`
      : filledPath;

  const swrKey = hasMissingValues(path, query) ? null : url;
  const swrData = useSWR(swrKey, fetchJSON, config);

  return { ...swrData, loading: swrKey ? !swrData.data : false };
};

export const useApiGetInfinite: ApiGetInfiniteSignature = (
  endpoint,
  path,
  query,
  config
) => {
  const getKey = (pageIndex: number, previousPageData: any) => {
    // reached the end
    if (previousPageData && !previousPageData.data) return null;

    const filledPath = endpoint ? fillPath(endpoint.axios.path, path) : null;

    const url =
      filledPath && query
        ? `${filledPath}?${new URLSearchParams(query)}`
        : filledPath;

    const swrKey = hasMissingValues(path, query) ? "" : url;

    // first page, we don't have `previousPageData`
    if (pageIndex === 0 || !previousPageData.cursorId) return swrKey;

    // add the cursor to the API endpoint
    return `${swrKey}&cursorId=${previousPageData.cursorId}`;
  };

  const swrData = useSWRInfinite(getKey, fetchJSON, {
    ...config,
    revalidateFirstPage: false,
    revalidateOnMount: true,
  });

  const data = swrData.data ? [].concat(...swrData.data) : undefined;

  return {
    ...swrData,
    data,
    loading: swrData.isLoading || swrData.isValidating,
  };
};

type InfiniteResponse<T> = {
  data: Array<T>;
  cursorId?: string;
};

export const useSDKApiGetInfiniteWithCursor = <
  T extends InfiniteResponse<K>,
  K,
  Q extends { limit: number },
>(
  key: string,
  getFetcher: (params: { cursorId?: string }) => Promise<T | undefined>,
  query: Q,
  config: SWRInfiniteConfiguration
) => {
  const getKey = (pageIndex: number, previousPageData: T) => {
    // reached the end
    if (previousPageData && !previousPageData.data) return null;

    const keyWithQuery = `${key}?${new URLSearchParams(query as any)}`;
    // first page, we don't have `previousPageData`
    if (pageIndex === 0 || !previousPageData.cursorId) return keyWithQuery;

    // add the cursor to the API endpoint
    // TODO FIx query type?
    return `${keyWithQuery}&cursorId=${previousPageData.cursorId}`;
  };

  const fetcher = async (key: string) => {
    const cursorId = key.split("cursorId=")[1] ?? undefined;
    return getFetcher({ cursorId });
  };

  const swrData = useSWRInfinite(getKey, fetcher, {
    ...config,
    revalidateFirstPage: false,
    revalidateOnMount: true,
  });

  const items: K[] = swrData.data?.flatMap((page) => page.data) || [];

  const loading = swrData.isLoading || swrData.isValidating;

  const loadMore = useCallback(() => {
    const { size, setSize } = swrData;
    if (!last(swrData.data)?.cursorId) return;
    if (loading) return;
    setSize(size + 1);
  }, [swrData, loading]);

  return {
    swrData,
    items,
    loading,
    loadMore,
  };
};

export const useSDKApiGetInfinite = <T>(
  endpoint: string,
  fetcher: (key: string) => Promise<{ data: T; cursorId?: string } | undefined>,
  query: any,
  config: any
) => {
  const getKey = (pageIndex: number, previousPageData: any) => {
    // reached the end
    if (previousPageData && !previousPageData.data) return null;

    const filledPath = endpoint;

    const url =
      filledPath && query
        ? `${filledPath}?${new URLSearchParams(query)}`
        : filledPath;

    const swrKey = hasMissingValues(query) ? "" : url;

    // first page, we don't have `previousPageData`
    if (
      pageIndex === 0 ||
      (!previousPageData.cursorId && !previousPageData.cursor)
    )
      return swrKey;

    if (previousPageData.cursor) {
      const invoiceId = previousPageData.cursor?.invoiceId;
      const formId = previousPageData.cursor?.formId;
      const clientId = previousPageData.cursor?.clientId;
      if (invoiceId && clientId)
        return `${swrKey}&cursorInvoiceId=${invoiceId}&cursorClientId=${clientId}`;

      if (formId && clientId)
        return `${swrKey}&cursorFormId=${formId}&cursorClientId=${clientId}`;
    }
    // add the cursor to the API endpoint
    return `${swrKey}&cursorId=${previousPageData.cursorId}`;
  };

  const swrData = useSWRInfinite(getKey, fetcher, {
    ...config,
    revalidateFirstPage: false,
    revalidateOnMount: true,
  });

  return {
    ...swrData,
    data: swrData.data,
    loading: swrData.isLoading || swrData.isValidating,
  };
};

export const useApiGetMutate: ApiGetMutateSignature = (
  endpoint,
  path,
  query,
  config
) => {
  const { mutate } = useSWRConfig();
  const filledPath = endpoint ? fillPath(endpoint.axios.path, path) : null;

  const url =
    filledPath && query
      ? `${filledPath}?${new URLSearchParams(query)}`
      : filledPath;

  if (config?.ignoreQuery) {
    return () =>
      mutate((key) => typeof key === "string" && url && key.startsWith(url));
  }

  const swrKey = hasMissingValues(path, query) ? null : url;

  return () => swrKey && mutate(url);
};

export const useMutate = <K extends SchemaDefinition>() => {
  const { mutate: swrMutate } = useSWRConfig();

  const mutate = (
    endpoint?: K,
    path?: z.input<K["input"]>["path"],
    query?: z.input<K["input"]>["query"],
    config?: {
      ignoreQuery?: boolean;
    }
  ) => {
    const filledPath = endpoint ? fillPath(endpoint.axios.path, path) : null;
    const url =
      filledPath && query
        ? `${filledPath}?${new URLSearchParams(query)}`
        : filledPath;

    if (config?.ignoreQuery) {
      return () =>
        swrMutate(
          (key) => typeof key === "string" && url && key.startsWith(url)
        );
    }

    const swrKey = hasMissingValues(path, query) ? null : url;

    return () => swrKey && swrMutate(url);
  };
  return { mutate };
};

export const useRevalidation = <K extends SchemaDefinition>(
  endpoint: K,
  path: z.input<K["input"]>["path"]
) => {
  const { mutate } = useSWRConfig();

  const url = fillPath(endpoint.axios.path, path);

  return () =>
    mutate((key) => typeof key === "string" && key.startsWith(url), undefined, {
      revalidate: true,
    });
};
